<template>
  <div class="operation-title">
    <div></div>
    <p>{{ title }}</p>
  </div>
</template>
<script>
export default ({
  props: {
    title: {
      type: String,
      default: ''
    }
  }
})

</script>
<style scoped lang="scss">
.operation-title {
  display: flex;
  align-items: center;
  div {
    width: 4px;
    height: 14px;
    background: #005CFD;
  }

  p {
    margin: 15px 0 15px 10px;
  }
}
</style>